import { Area, AreaConfig } from '@ant-design/charts';
import { Empty, Row, Select } from 'antd';
import {
  AcceptedChartColor,
  CompletedChartColor,
  DefinedChartColor,
  IdeaChartColor,
  InProgressChartColor,
  ReleasedChartColor,
} from 'app/common/constants';

import { IDataChartProps } from 'app/types/CacheReportModel';
import { useEffect, useState } from 'react';
import { LegendItem } from '@antv/g2/esm/interface';

export enum ThroughputType {
  UserStories,
  Defects,
  StoriesDefects,
}

export const Throughput = ({ teamId, iteration, dataSource }: IDataChartProps) => {
  const [chartData, setChartData] = useState([]);
  const [selected, setSelected] = useState(ThroughputType.UserStories);
  dataSource.defect = [];
  dataSource?.userStoryDefect?.forEach((element) => {
    let formatDate = new Date(element?.date)
      .toLocaleDateString('en-US', { day: 'numeric', month: 'numeric' })
      .replace('/', '-');
    let getUSData = dataSource?.userStory?.find((item) => item.dateId === formatDate);
    dataSource.defect.push({
      idea: element.idea ? element.idea - getUSData?.idea : null,
      defined: element.defined ? element.defined - getUSData?.defined : null,
      inProgress: element.inProgress ? element.inProgress - getUSData?.inProgress : null,
      completed: element.completed ? element.completed - getUSData?.completed : null,
      accepted: element.accepted ? element.accepted - getUSData?.accepted : null,
      released: element.released ? element.released - getUSData?.released : null,
      dateId: getUSData?.dateId,
    });
  });

  useEffect(() => {
    switch (selected) {
      case ThroughputType.UserStories:
        setChartData(dataSource.userStory);
        break;
      case ThroughputType.Defects:
        setChartData(dataSource.defect);
        break;
      case ThroughputType.StoriesDefects:
        setChartData(dataSource.userStoryDefect);
        break;
      default:
        setChartData(dataSource.userStoryDefect);
        break;
    }
  }, [selected, dataSource]);

  let data = [];
  if (chartData && chartData.length > 0) {
    for (var i = 0; i < chartData.length; i++) {
      let date =
        selected === ThroughputType.StoriesDefects
          ? new Date(chartData[i].date)
              .toLocaleDateString('en-US', { day: 'numeric', month: 'numeric' })
              .replace('/', '-')
          : chartData[i].dateId;
      data.push({
        time: date,
        value: chartData[i].idea,
        type: 'Idea',
      });
      data.push({
        time: date,
        value: chartData[i].defined,
        type: 'Defined',
      });
      data.push({
        time: date,
        value: chartData[i].inProgress,
        type: 'In Progress',
      });
      data.push({
        time: date,
        value: chartData[i].completed,
        type: 'Completed',
      });
      data.push({
        time: date,
        value: chartData[i].accepted,
        type: 'Accepted',
      });
      data.push({
        time: date,
        value: chartData[i].released,
        type: 'Released',
      });
    }
  }

  let stepInterval = 0;
  let maxValue = 0;
  if (data && data.length > 0) {
    maxValue = Math.max(
      ...data
        .filter((x) => x.value)
        .map((object) => {
          return object.value;
        })
    );
    stepInterval = Math.ceil((maxValue * 1.1) / 5);
  }

  const config: AreaConfig = {
    data,
    xField: 'time',
    yField: 'value',
    seriesField: 'type',
    width: 500,
    height: 300,
    color: [
      IdeaChartColor,
      DefinedChartColor,
      InProgressChartColor,
      CompletedChartColor,
      AcceptedChartColor,
      ReleasedChartColor,
    ],
    legend: {
      position: 'top',
      flipPage: false,
      items: customTargetLegend,
    },
    yAxis: {
      title: {
        text: 'Items',
        position: 'center',
        spacing: 20,
        style: { fontSize: 16, r: 120, fontWeight: 'bold', color: '#000000' },
      },
      tickInterval: stepInterval,
    },
    xAxis: {
      range: [0, 1],
    },
    areaStyle: () => {
      return {
        fillOpacity: 1,
      };
    },
  };
  const options = [
    { value: ThroughputType.UserStories, label: 'User Stories' },
    { value: ThroughputType.Defects, label: 'Defects' },
    { value: ThroughputType.StoriesDefects, label: 'Stories & Defects' },
  ];
  const thoughputOptionChange = (value: number) => {
    const selectedOption = value;
    setSelected(selectedOption);
  };
  return (
    <>
      <Row justify="end" className="thoughtput-filter">
        <Select value={selected} options={options} onChange={thoughputOptionChange} className="select-filter" />
      </Row>
      {!data?.length || !iteration?.isCurrentSprint ? (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      ) : (
        <Area {...config} />
      )}
    </>
  );
};

export const customTargetLegend: LegendItem[] = [
  {
    name: 'Idea',
    value: 'Idea',
    marker: {
      symbol: 'circle',
      style: {
        fill: IdeaChartColor,
      },
    },
  },
  {
    name: 'Defined',
    value: 'Defined',
    marker: {
      symbol: 'circle',
      style: {
        fill: DefinedChartColor,
      },
    },
  },
  {
    name: 'In Progress',
    value: 'In Progress',
    marker: {
      symbol: 'circle',
      style: {
        fill: InProgressChartColor,
      },
    },
  },
  {
    name: 'Completed',
    value: 'Completed',
    marker: {
      symbol: 'circle',
      style: {
        fill: CompletedChartColor,
      },
    },
  },
  {
    name: 'Accepted',
    value: 'Accepted',
    marker: {
      symbol: 'circle',
      style: {
        fill: AcceptedChartColor,
      },
    },
  },
  {
    name: 'Released',
    value: 'Released',
    marker: {
      symbol: 'circle',
      style: {
        fill: ReleasedChartColor,
      },
    },
  },
];
